/* eslint-disable */
import HttpService from '../HttpService'

export default class ContentVersionService {
  constructor() {
    this._httpService = new HttpService(`salesforce`);
  }

  async FindAll(documentid) {
    let params = {
      contentDocumentId: documentid,
      isLatest: true
    }

    return await this._httpService.get('/content_version', params);
  }
  async FindById(Id) {
    return await this._httpService.get('/content_version', { Id });
  }

  async Create(data) {
    data.Description = 'CLIENT';
    return await this._httpService.post('/content_version', data);
  }

  async getDownloadFile(id) {
    let url = '/content_version/' + id + '/download'
    return await this._httpService.getBlob(url);
  }
}
