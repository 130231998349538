/* eslint-disable */
import HttpService from '../HttpService'

export default class ComentsService {
  constructor() {
    this._httpService = new HttpService(`salesforce`);
  }

  async FindAll() {
    return await this._httpService.get('/custom_case_comment');
  }
  async FindById(id) {
    return await this._httpService.get('/custom_case_comment', { case__c: id });
  }

  async Create(data) {
    return await this._httpService.post('/custom_case_comment', data);
  }
}
